import React, { FC } from 'react';
import { graphql } from 'gatsby';
import { useLocation } from '@reach/router';
import { useCurrentLang } from 'hooks/useCurrentLang';
import useSiteTree from 'hooks/useSiteTree';

import Layout from 'layout/Layout';
import PageSchema from 'common/PageSchema';
import Seo from 'common/Seo';
import Footer from 'components/Footer';
import Header from 'components/Header';
import ListTree from 'components/ListTree';

const SitemapPage: FC<SitemapTypes.SitemapProps> = ({
  data: {
    sitemap: { urls, seo },
    footer,
    header,
    siteSettings,
  },
}) => {
  const { title, description, keywords } = seo;

  const {
    currentLang: [currentLangCode],
    defaultLang: [defaultLangCode],
  } = useCurrentLang();

  const tree = useSiteTree({
    lang: currentLangCode,
    defaultLang: defaultLangCode,
  });

  const location = useLocation();
  const eventData = { eventType: 'page_view', tagData: { pageTemplate: location.pathname } };

  return (
    <Layout headerTransparent>
      {header && <Header data={header} settings={siteSettings} />}
      <Seo {...{ urls, seo }} />
      <PageSchema
        type="WebPage"
        name={title}
        data={{
          metaTitle: title,
          metaDescription: description,
          metaKeywords: keywords,
        }}
        tagEventData={eventData}
      />
      <h1>{title}</h1>
      <ListTree items={tree} />
      {footer ? <Footer data={footer} /> : <></>}
    </Layout>
  );
};

export const query = graphql`
  query ($lang: String) {
    sitemap(lang: { eq: $lang }) {
      urls {
        lang
        href
      }
      seo {
        ...SEOStructureFragment
      }
    }
    header(lang: { eq: $lang }) {
      logo {
        desktop {
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED, width: 200)
          }
        }
        mobile {
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED, width: 70)
          }
        }
        tablet {
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED, width: 70)
          }
        }
      }
      socialMedia {
        name
        url
        queryString
      }
      navs {
        label
        url {
          url
        }
        navigationWithSubMenu {
          label
          url {
            name
            url
          }
        }
      }
      sidebar {
        url {
          url
        }
        image {
          desktop {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 60)
            }
          }
          mobile {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 60)
            }
          }
          tablet {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 60)
            }
          }
        }
        url {
          url
        }
      }
    }
    siteSettings {
      siteName
      defaultLang
      popupLanguage
      avaliableLanguages {
        url
        code
        title
        longTitle
      }
    }
    footer(lang: { eq: $lang }) {
      logo {
        desktop {
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED, width: 150)
          }
        }
        mobile {
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED, width: 150)
          }
        }
        tablet {
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED, width: 150)
          }
        }
      }
      extraLogo {
        childImageSharp {
          gatsbyImageData(quality: 100)
        }
      }
      navs {
        name
        url
      }
      contact
      copyright
      socialMedia {
        iconImage {
          url
          altText
          fallbackUrl
        }
        iconLink {
          url
          name
          icon
        }
      }
    }
  }
`;

export default SitemapPage;
